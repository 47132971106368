<!-- Footer -->
<footer>
  <div class="container">
    <span routerLink="" class="footer-logo clickable">
      <img src="../../../assets/img/logo.png" alt="" />
    </span>
    <p>
      &copy; <span id="copyright">{{ currentYear }}</span> copyright all right
      reserved
    </p>
  </div>
</footer>
