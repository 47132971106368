<div class="register-area">
  <div class="register">
    <ng-container *ngIf="sectId === 'forgot-password'">
      <h5>Forgot password</h5>
      <form [formGroup]="forgotPasswordForm">
        <div class="input-fields">
          <input
            type="email"
            placeholder="Email"
            formControlName="email"
            autocomplete
          />
        </div>
        <span class="rc">{{ errorMessage }}</span>
        <div class="form-btn">
          <button class="btn prim-btn clickable" (click)="forgetPassword()">
            <span *ngIf="loading">Loading...</span>
            <span *ngIf="!loading">Send link to email</span>
          </button>
          <span class="form-extra clickable" routerLink="/"
            >Go back <strong>Home</strong></span
          >
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="sectId === 'email-sent'">
      Link has been sent to <strong>{{ userEmail }}</strong
      >!
    </ng-container>
  </div>
</div>
