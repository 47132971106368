<!-- Acount container -->
<section class="account-container">
  <!-- Acount banner -->
  <div class="account-banner">
    <div class="account-banner-bg">
      <div class="cover-image">
        <h2>Hi, Welcome back!</h2>
      </div>
    </div>
    <div class="account-banner-info">
      <div class="account-banner-info-image" #accountBannerInfoImage>
        <img [src]="user?.imageUrl" alt="image" class="profile-image" />
      </div>
      <div class="account-banner-info-details">
        <div class="profile-name">
          <h4>{{ user?.fullName }}</h4>
        </div>
        <div class="profile-email">
          <h4>{{ user?.emailAddress }}</h4>
          <p>Email</p>
        </div>
      </div>
    </div>
    <!-- Acount banner edit -->
  </div>

  <!-- Account profile -->
  <div class="account-profile">
    <div class="account-profile-heading">
      <h3>Profile Details</h3>
    </div>
    <div class="account-profile-details">
      <div class="account-info">
        <form action="">
          <!-- User Information -->
          <div class="user-info">
            <h5 class="">User Information</h5>
            <div class="user-name">
              <label for="name">Username</label>
              <input type="text" name="name" [(ngModel)]="user.userName" />
            </div>
            <div class="email">
              <label for="email">Email</label>
              <input
                type="email"
                name="email"
                [(ngModel)]="user.emailAddress"
              />
            </div>
            <div class="full-name">
              <label for="fullname">Full Name</label>
              <input
                type="text"
                id="fullname"
                name="fullname"
                [(ngModel)]="user.fullName"
              />
            </div>
          </div>
          <!-- Contact Information -->
          <div class="contact-info">
            <h5 class="">Contact Information</h5>
            <div class="address">
              <label for="address">Address</label>
              <input
                type="text"
                placeholder="14B Parkview"
                id="address"
                class=""
              />
            </div>
            <div class="country">
              <label for="country">Country</label>
              <input type="text" placeholder="Isfahan" id="country" class="" />
            </div>
            <div class="number">
              <label for="number">Mobile Number</label>
              <input type="tel" name="number" [(ngModel)]="user.telNumber" />
            </div>
          </div>

          <div class="update-btn account-info">
            <button class="btn sec-btn">Update</button>
            <span class="success-msg">
              <img src="../../assets/img/svg/checked.svg" alt="" />
              <p>All Changes are saved</p>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
