<!-- Contact Us -->
<section class="contact sect" id="contact">
  <div class="container">
    <div class="contact-form-area">
      <h5>Get in touch with us</h5>
      <form (ngSubmit)="submit()" [formGroup]="contactForm">
        <input type="text" placeholder="Name" formControlName="name" />
        <input type="email" placeholder="Email" formControlName="email" />
        <textarea
          id=""
          cols="10"
          rows="5"
          placeholder="Message"
          formControlName="message"
        ></textarea>
        <div class="form-btn">
          <button class="btn sec-btn clickable" (click)="submit()">Send</button>

          <!-- Message -->
          <span class="status gc" *ngIf="success">Message Sent!</span>
          <span class="status rc" *ngIf="failed">Oops! Failed</span>
        </div>
      </form>
    </div>
    <div class="contact-image">
      <iframe
        width="490"
        height="290"
        src="https://www.youtube.com/embed/SavTi8TY1Cw"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</section>
