<!-- Testimonial -->
<section class="testi sect" id="testi">
  <div class="container">
    <div class="sect-heading">
      <h2>What people say about us</h2>
    </div>
    <div class="testi-body">
      <div class="testi-item">
        <span class="quote"><i class="bx bxs-quote-alt-left"></i></span>
        <p>
          SuperTrade was easy for us to use from the very start,Within two
          weeks, we had analystics functionaing. Their response to mails are
          always fast and realible
        </p>
        <figure>
          <!-- <img src="../../../assets/img/testimony2.jpg" alt="image" /> -->
          <figcaption>Douglas William</figcaption>
        </figure>
      </div>
      <div class="testi-item">
        <span class="quote"><i class="bx bxs-quote-alt-left"></i></span>
        <p>
          In 2month , i can only smile at how much i was able to acquire. Thanks
          to SuperTrade for helping me out of my financials crisis.
        </p>
        <figure>
          <!-- <img src="../../../assets/img/testi.jpg" alt="image" /> -->
          <figcaption>Hassan Muhammed</figcaption>
        </figure>
      </div>
      <div class="testi-item">
        <span class="quote"><i class="bx bxs-quote-alt-left"></i></span>
        <p>
          Got to know about Supertrade from my brother, my brother literally
          begged me to join and contribute to it An increase on your current
          available balance , will conveince you.
        </p>
        <figure>
          <!-- <img src="../../../assets/img/testimony.jpg" alt="image" /> -->
          <figcaption>Luis Florence</figcaption>
        </figure>
      </div>
    </div>
  </div>
</section>
