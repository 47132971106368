<h5>Login</h5>
<form (ngSubmit)="signin()" [formGroup]="signinForm">
  <div class="input-fields">
    <input type="email" placeholder="Email" formControlName="email" />
  </div>
  <div class="input-fields password-field">
    <input
      placeholder="Password"
      formControlName="password"
      [type]="hide ? 'password' : 'text'"
    />
    <img
      [src]="
        hide
          ? '../../../assets/img/bx-show.svg'
          : '../../assets/img/bx-hide.svg'
      "
      class="clickable"
      (click)="hide = !hide"
    />
  </div>
  <span class="form-extra clickable" (click)="forgotPassword()"
    ><strong>Forgot passwoword</strong></span
  >
  <span class="rc">{{ errorMessage }}</span>
  <div class="form-btn">
    <button class="btn prim-btn clickable" (click)="signin()">
      <span *ngIf="loading">Loading...</span>
      <span *ngIf="!loading">Login</span>
    </button>
    <span class="form-extra clickable" (click)="openSignupModal()"
      >New here? <strong>Register</strong></span
    >
  </div>
</form>
