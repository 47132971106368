<!-- Our Options -->
<section class="service sect" id="why-us">
  <div class="container">
    <!-- Change the class name -->
    <div class="sect-heading">
      <h2>Our Product</h2>
    </div>
    <div class="service-body">
      <div class="service-item">
        <div class="inner-box">
          <img src="../../assets/img/taxes-efficiency.png" alt="" />
          <h5>Starter</h5>
          <p><strong>$300</strong> USD</p>
          <br />
          <p>Min. Possible Deposit: Not Available</p>
          <br />
          <p>Max. Possible Deposit : $3000</p>
          <br />
          <p><strong>$1000</strong> Minimum return</p>
          <br />
          <p><strong>$10000</strong> Maximum return</p>
          <br />
          <p><strong>$5</strong> Gift Bonus</p>
        </div>
      </div>
      <div class="service-item">
        <div class="inner-box">
          <img src="../../assets/img/taxes-efficiency.png" alt="" />
          <h5>Premium</h5>
          <p><strong>$3000</strong> USD</p>
          <br />
          <p>Min. Possible Deposit: $3000</p>
          <br />
          <p>Max. Possible Deposit : $30000</p>
          <br />
          <p><strong>$6000</strong> Minimum return</p>
          <br />
          <p><strong>$50000</strong> Maximum return</p>
          <br />
          <p><strong>$10</strong> Gift Bonus</p>
        </div>
      </div>
      <div class="service-item">
        <div class="inner-box">
          <img src="../../assets/img/taxes-efficiency.png" alt="" />
          <h5>VIP</h5>
          <p><strong>$12000</strong> USD</p>
          <br />
          <p>Min. Possible Deposit: $12000</p>
          <br />
          <p>Max. Possible Deposit : $100000</p>
          <br />
          <p><strong>$20000</strong> Minimum return</p>
          <br />
          <p><strong>$200000</strong> Maximum return</p>
          <br />
          <p><strong>$50</strong> Gift Bonus</p>
        </div>
      </div>
    </div>
  </div>
</section>
