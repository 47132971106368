<section class="">
  <!-- Acount banner -->
  <div class="account-banner">
    <div class="account-banner-bg">
      <div class="cover-image">
        <h2>Hi, Welcome back!</h2>
      </div>
    </div>
  </div>

  <div class="plan-card">
    <div class="plan-card-item">
      <div class="plan-card-side plan-card-front" [class.show]="planPreview">
        <h4 class="uc">Starter investment plan</h4>
        <div>
          <ul>
            <li>Min. Possible Deposit $Not Available</li>
            <li>Max. Possible Deposit : $3000</li>
            <li>$10000 Minimum return</li>
          </ul>
        </div>
        <a class="btn sec-btn" (click)="openPlanPreview()">Invest</a>
      </div>
      <!-- Plan invest preview -->
      <div class="plan-card-side plan-card-preview" [class.show]="planPreview">
        <h4 class="uc">Investment Preview</h4>
        <div class="plan-card-preview-body">
          <h5 class="plan-card-preview-body-head">
            Account Balance ${{ user?.currentBal }}
          </h5>
          <form class="plan-card-preview-body-form">
            <input type="text" placeholder="Enter amount" id="amount" />
            <select name="" class="paymentMethod">
              <option value="">Select payment method</option>
              <option value="Btc">Btc</option>
              <option value="Etherum">Etherum</option>
              <option value="Usdt">Usdt</option>
            </select>
            <div>
              <button
                class="btn prim-btn"
                (click)="openDepositModal()"
                #investBtn
              >
                Invest
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="plan-card-item">
      <div class="plan-card-side plan-card-front" [class.show]="planPreview2">
        <h4 class="uc">Premium investment plan</h4>
        <div>
          <ul>
            <li>Min. Possible Deposit: $6000</li>
            <li>Max. Possible Deposit: $30000</li>
            <li>$6000 Minimum Return</li>
          </ul>
        </div>
        <a class="btn sec-btn" (click)="openPlanPreview2()">Invest</a>
      </div>
      <!-- Plan invest preview -->
      <div class="plan-card-side plan-card-preview" [class.show]="planPreview2">
        <h4 class="uc">Investment Preview</h4>
        <div class="plan-card-preview-body">
          <h5 class="plan-card-preview-body-head">
            Account Balance ${{ user?.currentBal }}
          </h5>
          <form class="plan-card-preview-body-form">
            <input type="text" placeholder="Enter amount" id="amount" />
            <select name="" class="paymentMethod">
              <option value="">Select payment method</option>
              <option value="Btc">Btc</option>
              <option value="Etherum">Etherum</option>
              <option value="Usdt">Usdt</option>
            </select>
            <div>
              <button
                class="btn prim-btn"
                (click)="openDepositModal()"
                #investBtn2
              >
                Invest
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="plan-card-item">
      <div class="plan-card-side plan-card-front" [class.show]="planPreview3">
        <h4 class="uc">VIP investment plan</h4>
        <div>
          <ul>
            <li>Min. Possible Deposit : $15000</li>
            <li>Max. Possible Deposit: $100000</li>
            <li>$20000 Minimum Return</li>
          </ul>
        </div>
        <a class="btn sec-btn" (click)="openPlanPreview3()">Invest</a>
      </div>
      <!-- Plan invest preview -->
      <div class="plan-card-side plan-card-preview" [class.show]="planPreview3">
        <h4 class="uc">Investment Preview</h4>
        <div class="plan-card-preview-body">
          <h5 class="plan-card-preview-body-head">
            Account Balance ${{ user?.currentBal }}
          </h5>
          <form class="plan-card-preview-body-form">
            <input type="text" placeholder="Enter amount" id="amount" />
            <select name="" class="paymentMethod">
              <option value="">Select payment method</option>
              <option value="Btc">Btc</option>
              <option value="Etherum">Etherum</option>
              <option value="Usdt">Usdt</option>
            </select>
            <div>
              <button
                class="btn prim-btn"
                (click)="openDepositModal()"
                #investBtn3
              >
                Invest
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Invest plan Modal -->
  <div class="plan-invest-area" [class.show-modal]="depositModal">
    <div class="plan-invest" #deposit>
      <h3 class="plan-invest-head">Deposit fund from your account</h3>
      <div class="plan-invest-body">
        <h5 class="heading">Account Balance $0</h5>
        <div class="body">
          <img src="https://www.svgrepo.com/show/15468/send.svg" alt="send" />
          <div class="body-details">
            <div class="user-details">
              <p><span class="uc">Name:</span> {{ user?.fullName }}</p>
              <p><span class="uc">Email:</span> {{ user?.emailAddress }}</p>
              <p><span class="uc">Option:</span> {{ option }}</p>
              <p>
                <span class="uc">Wallet:</span>
                <!-- <code class="gen-code" id="myInput">{{ optionId }}</code> -->
                <input
                  type="text"
                  [value]="optionId"
                  class="gen-code myInput"
                  id="myInput"
                  disabled
                />
              </p>
            </div>
            <div class="body-btns">
              <button
                (click)="myFunction()"
                (mouseenter)="outFunc()"
                class="btn sec-btn tooltip"
              >
                <span class="tooltiptext" id="myTooltip">{{ tooltip }} </span>
                Copy
              </button>
              <button class="btn prim-btn" (click)="depositBtn()">
                Deposit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
