<!-- Side Nav -->

<!-- Logo -->
<span routerLink="/" class="footer-logo clickable">
  <img src="../../../../assets/img/logo.png" alt="" />
</span>
<nav>
  <ul class="sidenav-list">
    <li
      class="clickable"
      routerLink="/dashboard"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Dashboard
    </li>
    <li class="clickable" routerLink="account" routerLinkActive="active">
      Account
    </li>
    <li class="clickable" routerLinkActive="active" routerLink="send">
      Deposit
    </li>
    <li class="clickable" routerLinkActive="active" routerLink="receive">
      Withdrawal
    </li>
  </ul>
</nav>
<span class="logout clickable" (click)="logOut()"
  >Log out <i class="bx bx-log-out"></i
></span>
