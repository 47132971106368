<!-- About -->
<section class="about sect" id="about">
  <div class="container about-content">
    <div class="sect-heading about-details">
      <h2>About Super Trade</h2>
      <p>
        SuperTrade Investment have become a real breakthrough in the development
        of finance and technology sector; They represent a fundamentally new
        instrument of payment. In Addition to opportunities that digital money
        offers in the field of global payments, this is also a great opportunity
        to make money,available to everyone.
      </p>
      <p>
        Due to their volatility , Independence and availability ,coins allow for
        speculative trading and getting huge interest revenue. No Investment
        instrument can display such Profotability and high liquidity. We posses
        experienced traders and beginners engage in high-yield earning, but most
        of them depend on good fortune from you and me.
      </p>
      <br />
      <p>
        SuperTrade investment company is under the supervision and regulation of
        the SEC company (the us securities and exchange commission) which is an
        independent federal goverment regulatory agency responsible for
        protecting investors, maintening fair and orderly functioning of the
        securities markets and facilitating capital formations.
      </p>
      <!-- <span class="btn sec-btn clickable" (click)="openCertModal()" #certBtn>View Certificate</span> -->
    </div>
    <div class="about-img">
      <img src="../../../assets/img/home-about.png" alt="image" />
    </div>
  </div>
</section>

<!-- Cert Modal -->
<div class="cert-area" [class.show-modal]="certModal">
  <div class="cert" #cert>
    <img src="../../../assets/img/cert.jpeg" alt="" />
  </div>
</div>
