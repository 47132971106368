<!-- Header -->
<app-header></app-header>

<!-- About -->
<app-about></app-about>

<!-- Services -->
<app-services></app-services>

<!-- Options -->
<app-options></app-options>

<!-- Testimonial -->
<app-testimonial></app-testimonial>

<!-- Contact -->
<app-contact></app-contact>

<!-- Btc Chart -->
<app-btcchart></app-btcchart>

<!-- Footer -->
<app-footer></app-footer>

<!-- Chat Bot -->
<!-- <a
  href="https://wa.me/message/FMQLM2FZKZM2K1"
  class="chat-bot"
  title="WhatsApp"
>
  <img src="../../../assets/img/bxl-whatsapp.svg" />
</a> -->
