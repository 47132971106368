<!-- Our Services -->
<section class="service sect" id="why-us">
  <div class="container">
    <!-- Change the class name -->
    <div class="sect-heading">
      <h2>Why choose us</h2>
    </div>
    <div class="service-body">
      <div class="service-item">
        <div class="inner-box">
          <img src="../../assets/img/taxes-efficiency.png" alt="" />
          <h5>Focused</h5>
          <p>
            SuperTrade is committed to helping its clients reach their goals,to personalizing their trading experience, to  providing an innovative platform, and to making a difference.
          </p>
        </div>
      </div>
      <div class="service-item">
        <div class="inner-box">
          <img src="../../assets/img/taxes-efficiency.png" alt="" />
          <h5>Diverse</h5>
          <p>
            This sense of identification also means we value and promote seamless interaction with clients;Our teams, amd ensure the best value is obtained from their event budget. We know that things change, and we are constantly striving to adapt and improve.
          </p>
        </div>
      </div>
      <div class="service-item">
        <div class="inner-box">
          <img src="../../assets/img/taxes-efficiency.png" alt="" />
          <h5>Investor Support</h5>
          <p>
            Our long experience at various top event business means we have expertise which reaches across a number of sectors, as well  as knowledge of specific <strong>protocol</strong> and <strong>local regulation</strong> .
          </p>
        </div>
      </div>
      <div class="service-item">
        <div class="inner-box">
          <img src="../../assets/img/taxes-efficiency.png" alt="" />
          <h5>Stategy</h5>
          <p>
            We strive to share the experience we acquire across our organizaton, which now extends to 12 offices around the world.With more than 25 nationalities represented, and as many languages spoken.
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="cg-container" style="width: 100%; background-color: rgb(51, 41, 27);"><div class="cg-widget"><div class="cg-marquee" style="background-color: rgb(51, 41, 27);"><span class="cg-marquee-content-1"><div class="cg-flex cg-flex-row"><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/bitcoin&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=bitcoin" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579" class="cg-mr-2"><span><span class="cg-bold">Bitcoin</span> (BTC)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $45,609
        </span><span>(3.93%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/eos&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=eos" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1547034481" class="cg-mr-2"><span><span class="cg-bold">EOS</span> (EOS)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $4.49
        </span><span>(4.89%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/ethereum&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=ethereum" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880" class="cg-mr-2"><span><span class="cg-bold">Ethereum</span> (ETH)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $3,102.58
        </span><span>(3.75%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/litecoin&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=litecoin" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/2/large/litecoin.png?1547033580" class="cg-mr-2"><span><span class="cg-bold">Litecoin</span> (LTC)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $166.02
        </span><span>(11.46%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><div class="cg-flex cg-items-center"><a class="cg-bold cg-sm cg-px-3">Powered by <a class="cg-primary-color-dark cg-no-underline" href="https://www.coingecko.com/en?utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=bitcoin,eos,ethereum,litecoin" target="_blank">CoinGecko</a></a></div></div></span><span class="cg-marquee-content-2"><div class="cg-flex cg-flex-row"><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/bitcoin&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=bitcoin" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579" class="cg-mr-2"><span><span class="cg-bold">Bitcoin</span> (BTC)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $45,609
        </span><span>(3.93%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/eos&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=eos" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1547034481" class="cg-mr-2"><span><span class="cg-bold">EOS</span> (EOS)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $4.49
        </span><span>(4.89%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/ethereum&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=ethereum" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880" class="cg-mr-2"><span><span class="cg-bold">Ethereum</span> (ETH)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $3,102.58
        </span><span>(3.75%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><a href="https://www.coingecko.com/resource_redirect?locale=en&amp;route=coins/litecoin&amp;utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=litecoin" target="_blank" class="cg-py-2 cg-px-3 cg-flex cg-coin-row cg-sm" style="color: rgb(249, 194, 145);"><div class="cg-flex cg-items-center"><img src="https://assets.coingecko.com/coins/images/2/large/litecoin.png?1547033580" class="cg-mr-2"><span><span class="cg-bold">Litecoin</span> (LTC)
      </span></div><div class="cg-ml-2 cg-flex cg-justify-end cg-items-center cg-primary-color-dark"><div><span class="cg-bold">
          $166.02
        </span><span>(11.46%)</span></div><div class="cg-ml-2 cg-arrow-up"></div></div></a><div class="cg-flex cg-items-center"><a class="cg-bold cg-sm cg-px-3">Powered by <a class="cg-primary-color-dark cg-no-underline" href="https://www.coingecko.com/en?utm_source=dynamicpremiumassets.com&amp;utm_medium=coin_price_marquee_widget&amp;utm_content=bitcoin,eos,ethereum,litecoin" target="_blank">CoinGecko</a></a></div></div></span></div></div></div>
      </div>
  </div>
</section>
